import React, { useState, useEffect} from 'react'
import * as S from './styles'
import logo from '../../../assets/images/logo.png'
import NavLink from '../../Buttons'
import PropTypes from 'prop-types'
import { withPrefix } from "gatsby"
import {closemenu} from '../../../images/totem'
import menuicon from '../../../../src/assets/images/totem/menu-icon.png'
import { navigate } from 'gatsby'

const NavbarTotem = ({ loja }) => {
  const  homeRedirect= () => {
    localStorage.clear();
  }; 
  const closeMenu = () => {
    localStorage.clear();
    navigate(`/br/totem/${loja.slug}/`)
  };

  const [itemsHome, setitemsHome] = useState([]);
  const [itemsClose, setitemsClose] = useState([]);
  const [itemsCadastro, setitemsitemsCadastro] = useState([]);

  

  useEffect(() => {
    const isHomepage = location.pathname === withPrefix(`/br/totem/${loja.slug}/`)
    setitemsHome(isHomepage)
    const menuNavbar = location.pathname === withPrefix(`/br/totem/${loja.slug}/menu`)
    setitemsClose(menuNavbar)
    const cadastroNavbar = location.pathname === withPrefix(`/br/totem/${loja.slug}/cadastro`) 
    setitemsitemsCadastro(cadastroNavbar)
  }, []);

  const menuNavbar = itemsHome || itemsCadastro

  return (
    <S.NavTotem>
      <S.NavLogo>
        {itemsHome ? (
          <NavLink to={`/totem/${loja.slug}/cadastro`} id="btn-cadastro">
            <img src={logo} alt="" />
          </NavLink>
        ) : (
          <NavLink to={`/totem/${loja.slug}/`} id="btn-home" onClick={homeRedirect}>
            <img src={logo} alt="" />
          </NavLink>
        )}
      </S.NavLogo>
      {menuNavbar === false && (
        <S.MenuTotemComponent>
          {itemsClose === false ? (
            <div>
              <NavLink to={`/totem/${loja.slug}/menu`} id="btn-menu">
                <img src={menuicon} alt="" />
              </NavLink>
            </div>
            ) : (
            <S.CloseMenu onClick={closeMenu}>
              <img src={closemenu} alt="" />
            </S.CloseMenu>
          )}
        </S.MenuTotemComponent>
      )}
    </S.NavTotem>
  )
}


NavbarTotem.propTypes = {
  loja: PropTypes.shape({
    nome: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
}

export default NavbarTotem
