import styled from 'styled-components'

export const NavTotem = styled.div`
  display: flex;
  justify-content: center;
  
`
export const NavLogo = styled.div`
    position: absolute;
    width: 474px;
    height: 79px;
    top: 35px;
    background: #ffffff;
    border-radius: 10px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 7px 1px #d7d7d7;
    a {
      background-color: #ffffff;
      &:hover {
        background-color: #ffffff;
      }
      @media (max-width: 440px) {
        width: 100%;
        border-radius: 7.687px;
        padding: 0;
        img{
          width: 80%;
          padding-top: 8px;
        }
      }
    }
    @media (max-width: 767px) {
      width: 65vw;
    }
    @media (max-width: 440px) {
      width: 160px;
      height: 40px;
    }
`


export const MenuTotemComponent = styled.div`
    display: flex;
    justify-content: center;
    div{
        position: absolute;
        width: 40px;
        height: 40px;
        top: 58px;
        right: 6vw;
        background: #FFFFFF;
        border-radius: 10px;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;  
        a{
            background-color: transparent !important;
            padding: 0;
        }
        @media (max-width: 767px) {
          top: 48px;
          img{
            width: 60px;
            height: 65px;
          }
        }
        @media (max-width: 440px) {
          width: 26px;
          height: 20px;
          img{
            width: 45px;
            height: 45px;
          }
        }
    }
    
`

export const CloseMenu = styled.button`
  position: absolute;
  top: 58px;
  right: 4vw;
  background: transparent;
  border: none;
  cursor: pointer;
  @media (max-width: 440px) {
      top: 40px;
      img{
        width: 60%;
      }
    }
`
